<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <Modal :name="'lightbox'" :is-open="$store.state.utils.modals.lightbox" :size="'large'">
      <div v-bind:class="{'horizontal': !isVertical}">
        <img ref="image"
             v-bind:src="'/storage/' + imageList[index]"
             v-bind:style="{'max-width': '100%', 'width': !isVertical ? '100%' : 'auto', 'height': isVertical ? '70vh' : 'auto'}"
             alt=""
        >
        <div class="controls">
          <div class="next" @click="nextImage()">&rarr;</div>
          <div class="prev" @click="prevImage()">&larr;</div>
        </div>
      </div>
    </Modal>
  </div>
</template>