import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";

export default {
    name: 'ProductFullDescription',
    props: {
        product: {
            type: Object,
            default: {}
        },


    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox
    },
    data() {
        return {
            expandDescription: false
        }
    },
    methods: {
        toggleDescription() {
            if (this.expandDescription) {
                const element = document.getElementById('product-description');
                setTimeout(() => {
                    element.scrollIntoView({behavior: "smooth"});
                }, 70);
            }
            this.expandDescription = !this.expandDescription;
        }
    },

}