
<template>
  <div v-bind:class="{

    'color-orange-main': color === 'orange-main',
    'color-orange-light': color === 'orange-light',
    'color-dark': color === 'dark',

  }">
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1349_6009)">
        <path d="M6.00686 1.25133C6.28844 0.918298 6.60287 0.649721 6.95484 0.440231C8.9916 -0.768363 11.5071 0.638978 11.9388 3.21194C12.1031 4.20567 11.9247 5.13495 11.5493 6.02662C11.1551 6.9559 10.6013 7.75626 9.98183 8.5029C8.88836 9.81892 7.64002 10.9147 6.30721 11.8923C6.10072 12.0427 5.92238 12.0481 5.71589 11.8923C4.27045 10.8395 2.93294 9.64704 1.78316 8.19135C1.14022 7.38025 0.581748 6.49932 0.253238 5.45724C-0.19729 4.02841 -0.0518069 2.68553 0.778854 1.48231C1.36548 0.622863 2.1586 0.139426 3.11128 0.0266235C4.22352 -0.102293 5.1762 0.316686 5.96462 1.21373C5.9787 1.22447 5.98808 1.22985 6.00686 1.25133ZM0.755389 4.11973C0.755389 4.32922 0.811705 4.68374 0.910258 5.03289C1.16368 5.92456 1.6189 6.67658 2.14921 7.37488C3.24268 8.82519 4.55203 9.98544 5.94115 11.0329C5.99278 11.0705 6.02563 11.0651 6.07256 11.0329C7.26927 10.1305 8.40498 9.13137 9.40459 7.94426C9.96305 7.27819 10.4699 6.56915 10.836 5.73656C11.1551 5.0114 11.3334 4.25402 11.2067 3.4268C10.9955 2.02483 9.9302 0.923669 8.68656 0.864582C7.71042 0.816239 6.9173 1.25133 6.32598 2.14838C6.13357 2.43844 5.86137 2.43844 5.66427 2.14838C4.98848 1.14927 4.1015 0.719551 3.00334 0.912926C1.71745 1.1439 0.750696 2.45993 0.755389 4.11973Z" fill="#FF7A00"/>
      </g>
      <defs>
        <clipPath id="clip0_1349_6009">
          <rect width="12" height="12" fill="white"/>
        </clipPath>
      </defs>
    </svg>


  </div>
</template>
<script>
export default {
  name: 'HeartIcon',
  props: {

    color: {
      type: String,
      default: ''
    }
  },

}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";
.color-orange-main  {
  svg {
   color: $color-orange-main;
  }
}

</style>