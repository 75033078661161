<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{'': true, 'label-new': labelnew === 'yes'}">
    <div class="gallery-container" v-if="images">
      <div class="row g-0">
        <div class="gallery-main-photo"
             @click="openLightbox(selectedImage)"
             v-bind:style="{'background-image': 'url(/storage/' + images[selectedImage].src + ')'}"
        ></div>
        <div class="svg-container d-flex">
          <div class="left-arrow me-auto" @click="prev()" v-bind:class="{'invisible': selectedImage <= 1}">
            <svg  width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.7071 8.70711C22.0976 8.31658 22.0976 7.68342 21.7071 7.29289L15.3431 0.928932C14.9526 0.538407 14.3195 0.538407 13.9289 0.928932C13.5384 1.31946 13.5384 1.95262 13.9289 2.34315L19.5858 8L13.9289 13.6569C13.5384 14.0474 13.5384 14.6805 13.9289 15.0711C14.3195 15.4616 14.9526 15.4616 15.3431 15.0711L21.7071 8.70711ZM1 7C0.447716 7 4.82823e-08 7.44771 0 8C-4.82823e-08 8.55228 0.447716 9 1 9L1 7ZM21 7L1 7L1 9L21 9L21 7Z" fill="black"/>
            </svg>
          </div>
          <div @click="next()" v-bind:class="{'invisible': selectedImage >= images.length - 1}">
            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.7071 8.70711C22.0976 8.31658 22.0976 7.68342 21.7071 7.29289L15.3431 0.928932C14.9526 0.538407 14.3195 0.538407 13.9289 0.928932C13.5384 1.31946 13.5384 1.95262 13.9289 2.34315L19.5858 8L13.9289 13.6569C13.5384 14.0474 13.5384 14.6805 13.9289 15.0711C14.3195 15.4616 14.9526 15.4616 15.3431 15.0711L21.7071 8.70711ZM1 7C0.447716 7 4.82823e-08 7.44771 0 8C-4.82823e-08 8.55228 0.447716 9 1 9L1 7ZM21 7L1 7L1 9L21 9L21 7Z" fill="black"/>
            </svg>
          </div>
        </div>
      </div>

      <div class="row g-0 gallery-thumb-container  ">
        <div class="col-3" v-if="images.length > 1">
          <div class="gallery-thumbnail-1 gallery-main-thumbnails"
               @click="openLightbox(1)"
               v-bind:style="{'background-image': 'url(/storage/' + images[1].src + ')'}">
          </div>
        </div>
        <div class="col-3" v-if="images.length > 2">
          <div class="gallery-thumbnail-2 gallery-main-thumbnails"
               @click="openLightbox(2)"
               v-bind:style="{'background-image': 'url(/storage/' + images[2].src + ')'}">

          </div>
        </div>
          <div class=" col-3" v-if="images.length > 3">
            <div class="gallery-thumbnail-3 gallery-main-thumbnails"
                 @click="openLightbox(3)"
                 v-bind:style="{'background-image': 'url(/storage/' + images[3].src + ')'}"></div>
          </div>
          <div class=" col-3 " v-if="images.length > 4">
            <div class="gallery-thumbnail-4 gallery-main-thumbnails"
                 @click="openLightbox(4)"
                 v-bind:style="{'background-image': 'url(/storage/' + images[4].src + ')'}">
              <div v-if="images.length > 5">+ {{ images.length-5 }}</div>
            </div>
          </div>
        </div>
    </div>
    <LightboxModal ref="lightbox" :images="images.map((item) => item.src)"/>
  </div>
</template>

