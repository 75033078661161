import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";
import LabelPromo from "@/components/Common/Labels/LabelPromo/LabelPromo";

import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'


export default {
    name: 'IndexMarketplaceCarousel',
    props: {
        products: {
            type: Array,
            default: null
        },
        carouseltype: {
            type: String,
            default: 'no'
        },
        washingmachine: {
            type: String,
            default: 'no'
        },
        fridge: {
            type: String,
            default: 'no'
        },
        oven: {
            type: String,
            default: 'no'
        },
        microwave: {
            type: String,
            default: 'no'
        },
        dishwasher: {
            type: String,
            default: 'no'
        },
        tv: {
            type: String,
            default: 'no'
        },
        sound: {
            type: String,
            default: 'no'
        },
        decorativelights: {
            type: String,
            default: 'no'
        },
        bed: {
            type: String,
            default: 'no'
        },
        sofa: {
            type: String,
            default: 'no'
        },
        kitchentable: {
            type: String,
            default: 'no'
        },
        coffeetable: {
            type: String,
            default: 'no'
        },


    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox,
        MarketplaceAd,
        LabelPromo,
        Carousel,
        Slide,
        Pagination,
        Navigation,


    },
    mounted() {
    },
    data: () => ({
        // carousel settings
        settings: {
            itemsToShow: 2.1,
            snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
            // 700px and up
            700: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            // 1024 and up
            1024: {
                itemsToShow: 6.3,
                snapAlign: 'start',
            },
        },
    }),

}