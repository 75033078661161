
export default {
    name: 'PlusMinusInput',
    props: ['modelValue'],
    methods: {
        inc() {
            this.value = this.modelValue > 100 ? 100 : this.modelValue + 1;
            this.$emit('update:modelValue', this.value);
        },
        dec() {
            this.value = this.modelValue <= 1 ? 1 : this.modelValue - 1;
            this.$emit('update:modelValue', this.value);
        },
        handleInput(e) {
            let value = parseInt(e.target.value);
            if (value < 1) {
                value = 1;
            }
            if (value > 100) {
                value = 100;
            }
            this.$refs.input.value = value.toString();
            this.$emit('update:modelValue', value);
        },
    },
}