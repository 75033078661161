<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
    <div class="number">
      <span class="minus" @click="dec()">-</span>
      <input type="text" v-model="modelValue" @change="handleInput" ref="input">
      <span class="plus" @click="inc()">+</span>
    </div>
</template>

