<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class=" " v-if="product">
    <div class="product-top-container">
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="d-flex d-md-none mb-3">
              <div class="arrow-left me-auto d-flex align-items-center">
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_439_34103)">
                    <path d="M15.7613 6.33301L0.52356 6.33301" stroke="#1A2128" stroke-width="1.42854" stroke-linecap="round"/>
                    <path d="M6.23771 0.619142L0.523559 6.33329L6.2377 12.0474" stroke="#1A2128" stroke-width="1.42854" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_439_34103">
                      <rect width="17.1424" height="13.333" fill="white" transform="translate(17.1425 13.333) rotate(-180)"/>
                    </clipPath>
                  </defs>
                </svg>
                <div class="ms-2">Назад</div>
              </div>
              <div>
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.95529 2.41747C9.39824 1.94232 9.77087 1.43837 10.2419 1.06401C12.8434 -1.03819 16.5416 0.113699 17.6525 3.35338C18.3767 5.46277 17.9197 7.39218 16.7385 9.1848C15.5854 10.9342 14.0246 12.2589 12.3723 13.4684C11.4302 14.1595 10.4388 14.7787 9.47558 15.4338C9.18731 15.6282 8.88498 15.6642 8.58265 15.477C6.49448 14.1595 4.45552 12.77 2.71889 10.9846C1.68534 9.91913 0.813513 8.74565 0.32135 7.30579C-0.416894 5.1388 0.145578 2.65505 1.71347 1.22239C3.82274 -0.69982 7.0007 -0.311059 8.71624 2.07911C8.80061 2.19429 8.87795 2.30948 8.95529 2.41747ZM4.85628 1.45277C3.68212 1.45997 2.74701 1.96392 2.10017 2.95742C1.38302 4.05891 1.2424 5.26839 1.57285 6.53547C1.88221 7.72335 2.56421 8.69525 3.36573 9.58076C4.94768 11.3302 6.85305 12.6621 8.81467 13.9075C8.90607 13.9651 9.10997 13.9507 9.2084 13.8859C10.0029 13.3604 10.8044 12.8348 11.5778 12.2733C13.0191 11.215 14.3831 10.0631 15.4307 8.57286C16.3518 7.26259 16.844 5.84433 16.4432 4.21729C15.9018 1.98551 13.6941 0.81923 11.7606 1.76234C10.72 2.27349 10.1224 3.1878 9.70057 4.25329C9.56698 4.59886 9.36308 4.84363 8.99044 4.83643C8.61781 4.83643 8.44203 4.57006 8.28032 4.24609C7.99206 3.67735 7.68973 3.1014 7.296 2.60465C6.67728 1.83433 5.84763 1.44557 4.85628 1.45277Z" fill="#1A2128"/>
                </svg>
              </div>
            </div>
            <GalleryProduct v-if="product.images.length > 0" :images="product.images"/>
          </div>
          <div class="col-md-7">
            <div class="product-title-id">
              <SingleProductTitleID :product="product"/>
            </div>
            <div id="product-description">
             <ProductShortDescription :product="product"/>
            </div>
            <div  class="product-price">
              <ProductPrice @addToCart="addToCart"  :product="product"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-middle-container container d-flex">
      <div class="col-md-8">
        <div class="description-wrapper" id="fulldescription">
          <ProductFullDescription :product="product"/>
        </div>
        <div class="description-wrapper" >
          <ProductCharacteristics :product="product"/>
        </div>
      </div>
      <div class="col-md-4 d-none d-md-block">
        <div class="vendor-profile-component">
          <VendorProfile
              :vendorId="product.vendor.id"
              :name="product.vendor.name"
              :date="product.vendor.createdAt"
              :description="product.vendor.description"
          />
        </div>
        <div class="buy-now-component">
          <BuyNow @addToCart="addToCart(1)" :product="product"/>
        </div>
      </div>
    </div>
    <div class="product-bottom-container">
      <div class="carousel-wrapper grey-wrapper" v-if="product.categories.length > 0 && similarProducts">
        <div class="container">
          <div> {{ $t('more-products-from-category') }} {{ product.categories[0].title }}</div>
          <IndexMarketplaceCarousel :products="similarProducts"/>
        </div>
      </div>
      <div class="carousel-wrapper" v-if="listByVendor">
        <div class="container">
          <div> {{ $t('more-products-from-vendor') }} {{ product.vendor.title }}</div>
          <IndexMarketplaceCarousel :products="listByVendor"/>
        </div>
      </div>
    </div>
  </div>

  <div class="">
    <Footer/>
  </div>
</template>

