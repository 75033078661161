import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIconOrange from "@/components/Common/Icons/HeartIconOrange";
import LabelDiscountGreen from "@/components/Common/Labels/LabelDiscountGreen/LabelDiscountGreen";
import AddToFavourites from "@/components/Components/AddToFavourites/AddToFavourites";

export default {
    name: 'BuyNow',
    props: {
        product: {
            type: Object,
            default: {}
        }
    },
    components: {
        AddToFavourites,
        Dropdown,
        InputField,
        Button,
        HeartIconOrange,
        LabelDiscountGreen

    },
    mounted() {
    },
    methods: {
        async addToCart() {
            this.$emit('addToCart');
        }
    }
}