<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'label-new-english': language === 'english',
    'label-new-bulgarian': language === 'bulgarian',
  }">
  <div class="label-new label-new-english" v-if="language === 'english'">PROMO</div>
  <div class="label-new label-new-bulgarian" v-if="language === 'bulgarian'">ПРОМО</div>
  </div>
</template>

