import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";

export default {
    name: 'VendorProfile',
    props: {
        date: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        vendorId: {
            type: Number,
            default: 1
        },
    },
    components: {
        Dropdown,
        InputField,
        Button

    },
    mounted() {
    }
}