
export default {
    name: 'SingleProductTitleID',
    props: {
        product: {
            type: Object,
            default: {}
        },
    },
    data () {
        return {
            translation: {
                product_id: 'Код на продукта',
            }
        }
    }
}