import LightboxModal from "../../../components/Modal/LightboxModal/LightboxModal";

export default {
    name: 'GalleryProduct',
    props: {
        photosamount: {
            type: String,
            default: ''
        },
        images: {
            type: Array,
            default: null
        }
    },
    components: {
        LightboxModal
    },
    data() {
        return {
            lightbox: null,
            selectedImage: 0
        }
    },
    mounted() {
        this.lightbox = this.$refs.lightbox;
    },
    methods: {
        prev() {
            if (this.selectedImage === 0) {
                return;
            }
            this.selectedImage--;
        },
        next() {
            if (this.selectedImage >= this.$props.images.length - 1) {
                return;
            }
            this.selectedImage++;
        },
        openLightbox(index) {
            this.selectedImage = index;
            this.lightbox.setIndex(index);
            this.$store.dispatch('utils/openModal', 'lightbox');
        }
    }
}