import Modal from './../Modal';

export default {
    name: 'LightboxModal',
    components: {
        Modal,
    },
    props: {
        images: {
            type: Array,
        }
    },
    data() {
        return {
            index: 0,
            imageList: [],
            isVertical: false
        }
    },
    mounted() {
        this.imageList = this.$props.images;
    },
    methods: {
        setIndex(index) {
            this.index = index;
            this.getImageOrientation();
        },
        nextImage() {
            if (this.index === this.imageList.length - 1) {
                return;
            }
            this.index++;
            this.getImageOrientation();
        },
        prevImage() {
            if (this.index === 0) {
                return;
            }
            this.index--;
            this.getImageOrientation();
        },
        getImageOrientation() {
            let vm = this;

            const img = new Image();
            img.onload = function() {
                vm.isVertical = this.height > this.width
            }

            img.src = '/storage/' + this.$props.images[this.index];
        }
    }
}