import Button from '@/components/Common/Buttons/Button/Button';
import HeartIcon from '@/components/Common/Icons/HeartIcon';
import InputField from '@/components/Common/Inputs/Input/Input';
import VendorProfile from '@/components/Components/VendorProfile/VendorProfile';
import BuyNow from '@/components/Components/BuyNow/BuyNow';
import GalleryProduct from '@/views/SingleProductPage/GalleryProduct/GalleryProduct';
import SingleProductTitleID from '@/views/SingleProductPage/SingleProductTitleID/SingleProductTitleID';
import RoommateAdContact from '@/components/Components/RoommateAdContact/RoommateAdContact';
import ProductShortDescription from '@/views/SingleProductPage/ProductShortDescription/ProductShortDescription';
import ProductFullDescription from '@/views/SingleProductPage/ProductFullDescription/ProductFullDescription';
import ProductPrice from '@/views/SingleProductPage/ProductPrice/ProductPrice';
import ProductCharacteristics from '@/views/SingleProductPage/ProductCharacteristics/ProductCharacteristics';
import IndexMarketplaceCarousel from '@/views/Index/IndexMarketplaceCarousel/IndexMarketplaceCarousel';
import moment from 'moment';


export default {
    name: 'SingleRoommateOffer',
    components: {
        Button,
        InputField,
        VendorProfile,
        BuyNow,
        GalleryProduct,
        SingleProductTitleID,
        RoommateAdContact,
        ProductShortDescription,
        ProductFullDescription,
        ProductPrice,
        ProductCharacteristics,
        IndexMarketplaceCarousel,
        HeartIcon
    },
    data() {
        return {
            product: null,
            translation: {
                more_products: 'Още продукти от',
                category: 'категория',
            },
            rightColPosition: 'relative',
            loaded: false,

            metaTitlePrefix: 'dasdasd.bg - ',
            page: {},
            components: {
                aboutIntro: {
                    component: 'Preview',
                },
            },
            similarProducts: null,
            listByVendor: null,
            quantity: 1
        };
    },
    methods: {
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD")
        },
        async addToCart(quantity) {
            const product = this.product;
            console.log(product);
            await this.$store.dispatch("cart/addProduct", {
                id: product.id,
                title: product.title,
                price: product.price,
                promoPrice: product.promoPrice,
                image: product.hasOwnProperty('mainImage') && product.mainImage !== null ? product.mainImage.src : '',
                vendor: product.vendor,
                categories: product.categories,
                quantity: quantity,
            });
            this.$store.dispatch("utils/openNotificationModal", {
                message: "Продукта беше добавен в количката!",
                type: 'success'
            });
        }
    },
    async mounted() {
        const id = this.$route.params.id;

        try {
            this.product = await this.$store.dispatch('product/getProduct', id);
        } catch (err) {
            if (err.response.status) {
                this.$router.push({name: 'NotFoundPage'})
                return;
            }
            await this.$store.dispatch('utils/openNotificationModal', {
                type: 'error', message: err.message
            });
            return;
        }

        this.similarProducts = await this.$store.dispatch('product/getProductsFromCategory', this.product.categories[0].id);
        this.listByVendor = await this.$store.dispatch('product/getProductsByVendor', this.product.vendor.id);
    },
};
