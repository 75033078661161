<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'full-description-wrapper': true,
  }">
    <div class="description-title"> {{ $t('specifications') }} </div>
    <div class="d-flex table-row table-rows" v-for="specification in product.specifications">
        <div class="me-auto" >
          {{ specification.title }}
        </div>
        <div class="" >
          {{ specification.value }}
        </div>
      </div>
    <div class="read-more-button">
      <Button :theme="'outline-grey'" :size="'medium'" :text="$t('more-specifications')"/>
    </div>






  </div>

</template>

