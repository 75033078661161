<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'short-description-wrapper': true,
  }">
    <div class="single-roommate-description">
      {{ product.shortDescription }}
    </div>
    <div class="read-more-button">
      <a href="#fulldescription"> {{ $t('read-more') }}</a>
    </div>
  </div>

</template>

