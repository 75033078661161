
export default {
    name: 'LabelDiscountGreen',
    props: {
        product: {
            type: Object,
            default: {}
        },

    },
    components: {

    },
    mounted() {
    }
}