export default {
    name: 'AddToFavourites',
    props: {
        product: {
            type: Object,
            default: null
        },
        property: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            isInFavourites: false,
            type: null,
        }
    },
    async mounted() {
        this.type = this.$props.product !== null ? 'product' : 'property';
        await this.checkIfIsInFavorites();
    },
    methods: {
        async toggleFavourites() {
            const action = !this.isInFavourites ? 'addToFavorites' :'removeFromFavorites';
            const item = this.$props.product ?? this.$props.property;
            item.type = this.type
            this.$store.dispatch('favorites/' + action, item);
            await this.checkIfIsInFavorites();
        },
        async checkIfIsInFavorites() {
            const item = this.$props.product !== null ? this.$props.product : this.$props.property;
            item.type = this.type
            this.isInFavourites = await this.$store.dispatch('favorites/isInFavorites', item);
        }
    }
}