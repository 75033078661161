<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'full-description-wrapper': true,
  }">
    <div class="description-title"> {{ $t('description') }} </div>
    <div v-bind:class="{'full-description': true, 'full-description-extended': expandDescription}">
      {{ product.description }}
    </div>
    <div class="read-more-button">
      <Button :theme="'outline-grey'"
              :size="'medium'"
              :text="expandDescription ? $t('short-description') : $t('full-description')"
              @click="toggleDescription()"
      />
    </div>
  </div>
</template>

