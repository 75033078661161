import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import PlusMinusInput from "@/components/Common/Inputs/PlusMinusInput/PlusMinusInput";
import HeartIconOrange from "@/components/Common/Icons/HeartIconOrange/";
import LabelDiscountGreen from "@/components/Common/Labels/LabelDiscountGreen/LabelDiscountGreen";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";
import AddToFavourites from "@/components/Components/AddToFavourites/AddToFavourites";
import addToFavourites from "@/components/Components/AddToFavourites/AddToFavourites";

export default {
    name: 'SingleRoommateDescription',
    computed: {
        addToFavourites() {
            return addToFavourites
        }
    },
    props: {
        product: {
            type: Object,
            default: {}
        },
    },
    components: {
        AddToFavourites,
        Dropdown,
        InputField,
        Button,
        PlusMinusInput,
        HeartIconOrange,
        LabelDiscountGreen,
        Checkbox

    },
    mounted() {
    },
    data() {
        return {
            translation: {
                saved_title: 'Спестяваш',
                order_quantity: 'Количество',
                order_piece: 'бр'
            },
            quantity: 1,
        }
    },
    methods: {
        async addToCart() {
            this.$emit('addToCart', this.quantity);
        }
    }
}